// extracted by mini-css-extract-plugin
export var container = "testimonials-module--container--LxT6W";
export var icon = "testimonials-module--icon--CU8Qk";
export var image = "testimonials-module--image--CowXr";
export var item = "testimonials-module--item--1yvHe";
export var items = "testimonials-module--items--jOwOY";
export var link = "testimonials-module--link--9N4oF";
export var name = "testimonials-module--name--gZRMF";
export var rocket = "testimonials-module--rocket--xvOgO";
export var teaser = "testimonials-module--teaser--53Bzy";
export var title = "testimonials-module--title--xkBi1";