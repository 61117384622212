// extracted by mini-css-extract-plugin
export var arrow = "teaser-module--arrow--Y-WSN";
export var background = "teaser-module--background--pF2c-";
export var banner = "teaser-module--banner--HUzJF";
export var bottom = "teaser-module--bottom--5+ZhF";
export var event = "teaser-module--event--xIqtp";
export var icon = "teaser-module--icon--sTKRS";
export var image = "teaser-module--image--S6AR9";
export var img = "teaser-module--img--6DedK";
export var info = "teaser-module--info--8HGyu";
export var item = "teaser-module--item--tMSRt";
export var languages = "teaser-module--languages--7omFu";
export var link = "teaser-module--link--1RJKV";
export var logo = "teaser-module--logo--b-ws-";
export var price = "teaser-module--price--LThMr";
export var sponsor = "teaser-module--sponsor--rwVgm";
export var text = "teaser-module--text--KMYEK";
export var ticket = "teaser-module--ticket--YcCgW";
export var title = "teaser-module--title--erTIw";
export var top = "teaser-module--top--wt6Hk";
export var wrapper = "teaser-module--wrapper--9H8Lp";