import React, { Fragment, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  Section,
  Container,
  Title,
  Text,
  Markdown,
  Button,
  getProperty,
  useLocale,
} from "@btc/shared";

import { useMeta } from "../../hooks";

import * as styles from "./tickets.module.scss";

export const Tickets = ({ data }) => {
  const { locale } = useLocale();
  const items = getProperty(data, "items") || [];

  useEffect(() => {
    if (window.PretixWidget) {
      window.PretixWidget.buildWidgets();
      return;
    }

    const styles = document.createElement("link");
    styles.href = "https://pretix.eu/btc/23/widget/v1.css";
    styles.rel = "stylesheet";
    styles.type = "text/css";
    document.body.appendChild(styles);

    const script = document.createElement("script");
    script.src = `https://pretix.eu/widget/v1.${locale}.js`;
    document.body.appendChild(script);
  }, [locale]);

  return Array.isArray(items) && items.length > 0 ? (
    <Fragment>
      <Section size="md" color="black">
        <Container>
          <div className={styles.items}>
            {items.map((item, index) => (
              <Ticket item={item} key={index} />
            ))}
          </div>
        </Container>
      </Section>
    </Fragment>
  ) : null;
};

Tickets.defaultProps = {
  data: {},
};

Tickets.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
};

const Ticket = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const meta = useMeta();

  const title = getProperty(item, "title");
  const price = getProperty(item, "price");
  const content = getProperty(item, "content");
  const linkId = getProperty(item, "link.id");
  const linkText = getProperty(item, "link.text");
  const moreText = getProperty(meta, "meta.more_link");

  const wrapperClassName = useMemo(
    () => [isOpen ? styles.open : "", styles.item].filter(Boolean).join(" "),
    [isOpen]
  );

  return (
    <div className={wrapperClassName}>
      <div className={styles.holder}>
        <Title wrapper="h3" size="s4" type="special" color="black" className={styles.title}>
          {title}
        </Title>
        <Text className={styles.price} size="s4" weight="bold">
          {price}
        </Text>
        <div className={styles.info}>
          <div className={styles.extra}>
            <Text className={styles.text}>
              <Markdown content={content} />
            </Text>
            <pretix-button
              skip-ssl-check="true"
              items={`item_${linkId}=1`}
              event="https://pretix.eu/btc/23/">
              {linkText}
            </pretix-button>
          </div>

          <div className={styles.more}>
            <Button type="minimal" onClick={() => setIsOpen((isOpen) => !isOpen)}>
              {moreText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Ticket.defaultProps = {
  item: {},
};

Ticket.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};
