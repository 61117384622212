import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { Button, Section, Container, Markdown, Text, getProperty } from "@btc/shared";

import * as styles from "./promo.module.scss";

export const Promo = ({ data }) => {
  const content = getProperty(data, "content");
  const linkPath = getProperty(data, "link.path");
  const linkText = getProperty(data, "link.text");

  return (
    <Section size="md" color="black" align="center" noPaddingTop={true}>
      <Container>
        <Text size="s4" weight="bold" className={styles.content}>
          <Markdown content={content} />
        </Text>
        <Button color="primary" type="border" wrapper={Link} to={linkPath}>
          {linkText}
        </Button>
      </Container>
    </Section>
  );
};

Promo.defaultProps = {
  data: {},
};

Promo.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string.isRequired,
    link: PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
