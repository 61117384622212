import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  Section,
  ContainerToLG,
  Text,
  Title,
  Markdown,

  ArrowIcon,
  getProperty,
  useMatchMedia,
  MD,
} from "@btc/shared";


import { Languages } from "theme";

import { Marquee } from "../common/marquee";

import * as styles from "./teaser.module.scss";
import {LogoLarge} from "../../theme";

const MEDIA_QUERIES = [`(min-width:${MD}px)`];

export const Teaser = ({ data }) => {
  const [isMD] = useMatchMedia(MEDIA_QUERIES);

  const title = getProperty(data, "title");
  const subtitle = getProperty(data, "subtitle");
  const banner = getProperty(data, "banner");
  const location = getProperty(data, "information.location");
  const date = getProperty(data, "information.date");
  const image = getProperty(data, "image.childImageSharp.gatsbyImageData");

  const sponsorUrl = getProperty(data, "sponsor.url");
  const sponsorName = getProperty(data, "sponsor.name");
  const sponsorSrc = getProperty(data, "sponsor.image.publicURL");

  const marquee = getProperty(data, "marquee");

  return (
    <Section color="black">
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.logo}>
            <LogoLarge color="initial" className={styles.icon}/>
            <Text
              size="s6"
              type="script"
              weight="bold">
              {subtitle}
            </Text>
          </div>

          <Text
            size="s4"
            type="script"
            weight="bold"
            className={styles.banner}>
            {banner}
          </Text>

          <div className={styles.event}>
            <Text
              size={isMD ? "s4" : "s3"}
              transform="uppercase"
              className={styles.item}>
              <Markdown content={date} className={styles.text} />
            </Text>
            <Text
              size={isMD ? "s4" : "s3"}
              transform="uppercase"
              className={styles.item}>
              <Markdown content={location} className={styles.text} />
            </Text>
          </div>

          <div className={styles.languages}>
            <ContainerToLG>
              <Languages />
            </ContainerToLG>
          </div>
          {/*{sponsorUrl && (
            <div className={styles.sponsor}>
              <ContainerToLG>
                <a href={sponsorUrl} className={styles.link} rel="noreferrer" target="_blank">
                  <img src={sponsorSrc} className={styles.img} alt={sponsorName} />
                </a>
              </ContainerToLG>
            </div>
          )}*/}
          {/*<div className={styles.info}>
            <ContainerToMD>
              {isMD && (
                <div className={styles.price}>
                  <PriceLarge />
                </div>
              )}
              <div className={styles.ticket}>
                <TicketLarge />
              </div>
            </ContainerToMD>
          </div>*/}
          {isMD && (
            <a href="#intro" className={styles.arrow}>
              <ArrowIcon className={styles.icon} />
            </a>
          )}
        </div>

        <div className={styles.bottom}>
          <Marquee>{marquee}</Marquee>
        </div>

        <div className={styles.background}>
          <GatsbyImage image={image} alt={title} className={styles.image} />
        </div>
      </div>
    </Section>
  );
};

Teaser.defaultProps = {
  data: {},
};

Teaser.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    sponsor: PropTypes.shape({
      url: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
};
