import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  Section,
  Container,
  Title,
  Text,
  Button,
  RocketColoredIcon,
  getProperty,
  MD,
} from "@btc/shared";

import * as styles from "./testimonials.module.scss";

export const Testimonials = ({ data }) => {
  const imageData = getProperty(data, "background.image.childImageSharp.gatsbyImageData");
  const imageTitle = getProperty(data, "background.title");
  const title = getProperty(data, "title");
  const items = getProperty(data, "items") || [];

  const linkUrl = getProperty(data, "link.url");
  const linkText = getProperty(data, "link.text");

  return (
    <Section size="sm" color="black">
      <div className={styles.teaser}>
        <Text size="s10" weight="bold" className={styles.title}>
          <Container>{imageTitle}</Container>
        </Text>
        <GatsbyImage image={imageData} alt={imageTitle} />
      </div>

      <Container>
        <div className={styles.container}>
          <Title>{title}</Title>
          <div className={styles.items}>
            {items.map((item, index) => {
              const name = getProperty(item, "name");
              const description = getProperty(item, "description");
              const image = getProperty(item, "image.childImageSharp.gatsbyImageData");

              return (
                <div key={index} className={styles.item}>
                  <GatsbyImage image={image} alt={name} className={styles.image} />
                  <Text className={styles.name}>{name}</Text>
                  <Text size="s1">{description}</Text>
                </div>
              );
            })}
          </div>
          <div className={styles.rocket}>
            <RocketColoredIcon className={styles.icon} />
            <div className={styles.link}>
              <Button
                size="md"
                color="white"
                wrapper="a"
                href={linkUrl}
                target="_blank"
                rel="noreferrer">
                <Text wrapper="span">{linkText}</Text>
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

Testimonials.defaultProps = {
  data: {},
};

Testimonials.propTypes = {
  data: PropTypes.shape({
    background: PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
    }),
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    link: PropTypes.object.isRequired,
  }).isRequired,
};
