import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  Button,
  Text,
  getProperty,
  IncognitoIcon,
  Container,
  Title,
  Markdown,
  Section,
  useFormatterDateFromTo,
  useFormatterTimeFromTo,
} from "@btc/shared";

import { useMeta } from "hooks";

import * as styles from "./speakers.module.scss";

const SpeakerPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  image: PropTypes.object,
  talks: PropTypes.arrayOf(
    PropTypes.shape({
      alias: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      schedule: PropTypes.shape({
        name: PropTypes.string.isRequired,
        alias: PropTypes.string.isRequired,
        event: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    })
  ),
});

export const Speakers = ({ title, content, items, linkProvider, children }) =>
  Array.isArray(items) && items.length > 0 ? (
    <Section size="md" color="black">
      <Container>
        {title && <Title className={styles.title}>{title}</Title>}
        {content && <Markdown content={content} className={styles.content} />}
        <div className={styles.items}>
          {items.map((item, index) => (
            <Item key={index} item={item} linkProvider={linkProvider} />
          ))}
        </div>
        {children}
      </Container>
    </Section>
  ) : null;

Speakers.defaultProps = {
  title: null,
  content: null,
  items: [],
  linkProvider: null,
};

Speakers.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  items: PropTypes.arrayOf(SpeakerPropType.isRequired).isRequired,
  linkProvider: PropTypes.func,
};

export const Item = ({ item, linkProvider }) => {
  const meta = useMeta();

  const schedule = getProperty(item, "talks.0.schedule.name");
  const event = getProperty(item, "talks.0.schedule.event.title");

  const name = getProperty(item, "name");
  const role = getProperty(item, "role");
  const image = getProperty(item, "image.childImageSharp.gatsbyImageData");

  const title = getProperty(item, "talks.0.title");
  const from = getProperty(item, "talks.0.from");
  const to = getProperty(item, "talks.0.to");

  const linkMoreText = getProperty(meta, "meta.more_link");

  const date = useFormatterDateFromTo(from, to);
  const time = useFormatterTimeFromTo(from, to);

  const path = typeof linkProvider === "function" ? linkProvider(item) : null;

  return (
    <div className={styles.item}>
      <div className={styles.border}>
        {image ? (
          <GatsbyImage image={image} alt={name} className={styles.image} />
        ) : (
          <IncognitoIcon className={styles.placeholder} />
        )}
        {title && (
          <div className={styles.overlay}>
            <div className={styles.content}>
              <Text transform="uppercase" color="primary">
                {schedule} / {event}
              </Text>
              <Text transform="uppercase" color="primary">
                {date}
              </Text>
              <Text transform="uppercase" color="primary">
                {time}
              </Text>
              <Text className={styles.title} truncate={125}>
                {title}
              </Text>
              {path && (
                <Button wrapper={Link} to={path} color="white">
                  {linkMoreText}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <Text className={styles.name}>
        <span>{name}</span>
        {role && <span> - {role}</span>}
      </Text>
    </div>
  );
};

Item.defaultProps = {
  item: {},
  linkProvider: null,
};

Item.propTypes = {
  item: SpeakerPropType.isRequired,
  linkProvider: PropTypes.func,
};

export const query = graphql`
  fragment CommonEventSpeakersFeaturedFragment on MarkdownRemark {
    frontmatter {
      event {
        title
        contents {
          speakers {
            title
            content
          }
        }
        featured {
          name
          role
          alias
          placeholder
          image {
            childImageSharp {
              gatsbyImageData(width: 300, height: 300, layout: CONSTRAINED)
            }
          }
          talks {
            alias
            title
            from
            to
            schedule {
              name
              alias
              event {
                title
              }
            }
          }
        }
      }
    }
  }

  fragment CommonEventSpeakersAllFragment on MarkdownRemark {
    frontmatter {
      event {
        title
        contents {
          speakers {
            title
            content
          }
        }
        speakers {
          name
          rank
          role
          alias
          placeholder
          image {
            childImageSharp {
              gatsbyImageData(width: 300, height: 300, layout: CONSTRAINED)
            }
          }
          talks {
            alias
            title
            from
            to
            schedule {
              name
              alias
              event {
                title
              }
            }
          }
        }
      }
    }
  }
`;
