import React from "react";
import PropTypes from "prop-types";

import { getProperty } from "@btc/shared";

import { usePageByView } from "hooks";

import { Highlights as CommonHighlights } from "../common/highlights";

export const Highlights = ({ data }) => {
  const page = usePageByView("program");
  const pagePath = getProperty(page, "path");

  const linkProvider = (item) => {
    const talkAlias = getProperty(item, "alias");
    const scheduleAlias = getProperty(item, "schedule.alias");

    return pagePath && scheduleAlias && talkAlias ? `${pagePath}/#${scheduleAlias}?id=${talkAlias}` : null;
  };

  return <CommonHighlights linkProvider={linkProvider} {...data} />;
};

Highlights.defaultProps = {
  data: {},
};

Highlights.propTypes = {
  data: PropTypes.shape({
    ...CommonHighlights.propTypes,
  }),
};
