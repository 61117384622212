import React, { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@btc/shared";

import { Meta } from "./common/meta";
import { Teaser } from "./home/teaser";
import { Intro } from "./home/intro";
import { Highlights } from "./home/highlights";
import { Schedules } from "./home/schedules";
import { Tickets } from "./home/tickets";
import { News } from "./home/news";
import { Newsletter } from "./home/newsletter";
import { Retrospect } from "./home/retrospect";
import { Speakers } from "./home/speakers";
import { Testimonials } from "./home/testimonials";
import { Ticket } from "./home/ticket";
import { Promo } from "./home/promo";
import { Sponsors } from "./home/sponsors";
import { Partners } from "./home/partners";
import { Exhibitors } from "./home/exhibitors";
import {MobilityPartners} from "./home/mobility-partners";

const Template = ({ data }) => {
  const teaser = getProperty(data, "page.frontmatter.home.teaser");
  const intro = {
    ...getProperty(data, "page.frontmatter.home.intro"),
    event: getProperty(data, "intro.frontmatter.event"),
  };
  const highlights = {
    title: getProperty(data, "highlights.frontmatter.event.contents.highlights.title"),
    content: getProperty(data, "highlights.frontmatter.event.contents.highlights.content"),
    items: getProperty(data, "highlights.frontmatter.event.highlights"),
  };
  const tickets = {
    items: getProperty(data, "page.frontmatter.home.tickets.items"),
  };
  const news = getProperty(data, "page.frontmatter.home.news");
  const newsletter = getProperty(data, "page.frontmatter.home.newsletter");
  const schedules = {
    items: getProperty(data, "schedules.frontmatter.event.schedules"),
  };
  const retrospect = getProperty(data, "page.frontmatter.home.retrospect");
  const speakers = {
    ...getProperty(data, "page.frontmatter.home.speakers"),
    title: getProperty(data, "speakers.frontmatter.event.contents.speakers.title"),
    content: getProperty(data, "speakers.frontmatter.event.contents.speakers.content"),
    items: getProperty(data, "speakers.frontmatter.event.featured"),
  };
  const testimonials = getProperty(data, "page.frontmatter.home.testimonials");
  const ticket = getProperty(data, "page.frontmatter.home.ticket");
  const promo = getProperty(data, "page.frontmatter.home.promo");
  const sponsors = getProperty(data, "sponsors.frontmatter.event.sponsors");
  const partners = getProperty(data, "partners.frontmatter.event.partners");
  const exhibitors = getProperty(data, "exhibitors.frontmatter.event.exhibitors");
  const mobility_partners = getProperty(data, "mobility_partners.frontmatter.event.mobility_partners");
  const meta = getProperty(data, "page.frontmatter.meta");

  return (
    <Fragment>
      <Teaser data={teaser} />
      <Intro data={intro} />
      <Highlights data={highlights} />
      <Schedules data={schedules} />
      {/*<Tickets data={tickets} />*/}
      <News data={news} />
      <Newsletter data={newsletter} />
      <Retrospect data={retrospect} />
      <Speakers data={speakers} />
      <Testimonials data={testimonials} />
      <Ticket data={ticket} />
      <Promo data={promo} />
      <Sponsors data={sponsors} />
      <Exhibitors data={exhibitors} />
      <Partners data={partners} />
      <MobilityPartners data={mobility_partners} />
      <Meta data={meta} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title

        meta {
          title
          description
        }

        home {
          teaser {
            title
            subtitle
            banner
            information{
              location
              date
            }
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 2560
                  height: 2400
                )
              }
            }
            sponsor {
              url
              name
              image {
                publicURL
              }
            }
            marquee
          }

          intro {
            title
            content
            link {
              url
              text
            }
          }

          tickets {
            items {
              title
              price
              content
              link {
                text
                id
              }
            }
          }

          news {
            title
            items {
              title
              content
            }
          }

          newsletter {
            title
            content
            labels {
              submit
              retry
            }
            rows {
              fields {
                name
                type
                label
                required
              }
            }
            success {
              title
              content
            }
            failure {
              title
              content
            }
          }

          retrospect {
            content
            items {
              name
              count
              suffix
              icon {
                publicURL
              }
            }
          }

          speakers {
            link {
              path
              text
            }
          }

          testimonials {
            background {
              title
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            title
            items {
              name
              description
              image {
                childImageSharp {
                  gatsbyImageData(width: 150, height: 150, layout: CONSTRAINED)
                }
              }
            }
            link {
              url
              text
            }
          }

          ticket {
            content
            items {
              text
              icon {
                publicURL
              }
            }
          }

          promo {
            content
            link {
              text
              path
            }
          }
        }
      }
    }

    intro: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...HomeEventIntroFragment
    }

    highlights: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...CommonEventHighlightsFragment
    }

    speakers: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...CommonEventSpeakersFeaturedFragment
    }

    schedules: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...HomeEventSchedulesFragment
    }

    sponsors: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...CommonEventSponsorsFragment
    }

    partners: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...CommonEventPartnersFragment
    }

    exhibitors: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...CommonEventExhibitorsFragment
    }

    mobility_partners: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...CommonEventMobilityPartnersFragment
    }
  }
`;
