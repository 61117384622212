import React from "react";
import PropTypes from "prop-types";

import { Title, Markdown, getProperty } from "@btc/shared";

import { Facts } from "../common/facts";

import * as styles from "./retrospect.module.scss";

export const Retrospect = ({ data }) => {
  const content = getProperty(data, "content") || "";
  const items = getProperty(data, "items") || [];

  return (
    <Facts
      header={
        <Title className={styles.title}>
          {content.split(" ").map((word, index) => (
            <span key={index} className={styles.word}>
              <Markdown content={word} inline={true} wrapper="span" />
            </span>
          ))}
        </Title>
      }
      items={items}
    />
  );
};

Retrospect.defaultProps = {
  data: {},
};

Retrospect.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        suffix: PropTypes.string,
      }).isRequired
    ).isRequired,
  }).isRequired,
};
