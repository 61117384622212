import React from "react";
import PropTypes from "prop-types";
import { CarouselProvider, Slider, Slide, ButtonNext, ButtonBack } from "pure-react-carousel";

import {
  Section,
  Container,
  Text,
  Title,
  Markdown,
  MD,
  useMatchMedia,
  getProperty,
  ArrowIcon,
} from "@btc/shared";

import * as styles from "./news.module.scss";

const MEDIA_QUERIES = [`(min-width: ${MD}px)`];

export const News = ({ data }) => {
  const [isLG] = useMatchMedia(MEDIA_QUERIES);
  const itemSize = isLG ? 2 : 1;

  const items = getProperty(data, "items") || [];
  const title = getProperty(data, "title");

  return Array.isArray(items) && items.length > 0 ? (
    <Section color="black" size="sm">
      <CarouselProvider
        step={itemSize}
        dragStep={itemSize}
        visibleSlides={itemSize}
        isIntrinsicHeight={true}
        lockOnWindowScroll={true}
        totalSlides={items.length}>
        <Container>
          <Title color="white">{title}</Title>
          <div className={styles.container}>
            <Slider className={styles.slider}>
              {items.map((item, index) => {
                const title = getProperty(item, "title");
                const content = getProperty(item, "content");

                return (
                  <Slide key={index} index={index} className={styles.slide}>
                    <div className={styles.inner}>
                      <Title
                        wrapper="h3"
                        type="special"
                        transform="uppercase"
                        className={styles.title}>
                        {title}
                      </Title>
                      <Text>
                        <Markdown content={content} />
                      </Text>
                    </div>
                  </Slide>
                );
              })}
            </Slider>
          </div>

          <div className={styles.buttons}>
            <ButtonBack className={styles.button}>
              <ArrowIcon className={styles.icon} />
            </ButtonBack>
            <ButtonNext className={styles.button}>
              <ArrowIcon className={styles.icon} />
            </ButtonNext>
          </div>
        </Container>
      </CarouselProvider>
    </Section>
  ) : null;
};

News.defaultProps = {
  data: {},
};

News.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};
