import React, { useRef } from "react";
import PropTypes from "prop-types";
import jsonp from "fetch-jsonp";

import { Section, Container, Markdown, Title, getProperty } from "@btc/shared";

import { Form } from "../common/form";

import * as styles from "./newsletter.module.scss";

const INITIAL_STATE = {
  u: "b7dc2dec09c9a5a628ba960df",
  id: "9c2cc14cd1",
  c: "?",
};

const FORM_ATTRS = {
  action: "https://bconf.us2.list-manage.com/subscribe/post",
  method: "POST",
};

const HIDDEN_FIELDS = Object.entries(INITIAL_STATE).map(([name, value]) => (
  <input key={name} type="hidden" name={name} value={value} />
));

export const Newsletter = ({ data }) => {
  const ref = useRef(null);
  const { title, content, ...formData } = data || {};

  const submitHandler = async (data) => {
    const response = await jsonp(
      `${FORM_ATTRS.action}-json?${new URLSearchParams(data).toString()}`,
      {
        jsonpCallback: "c",
      }
    );

    const json = await response.json();
    const message = getProperty(json, "msg");
    const result = getProperty(json, "result");

    if (result !== "success") {
      throw new Error(`Failed with response: ${message}`);
    }
  };

  return (
    <Section id="newsletter" ref={ref} color="black">
      <Container>
        <Title>{title}</Title>
        <Markdown content={content} />
        <div className={styles.form}>
          <Form
            {...formData}
            formAttrs={FORM_ATTRS}
            hiddenFields={HIDDEN_FIELDS}
            initialState={INITIAL_STATE}
            onTransmitData={submitHandler}
            ref={ref}
          />
        </div>
      </Container>
    </Section>
  );
};

Newsletter.defaultProps = {
  data: {},
};

Newsletter.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    labels: PropTypes.shape({
      retry: PropTypes.string.isRequired,
      submit: PropTypes.string.isRequired,
    }).isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      })
    ),
    success: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
    failure: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
