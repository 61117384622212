// extracted by mini-css-extract-plugin
export var buy = "tickets-module--buy--9B+D3";
export var content = "tickets-module--content--tA+NQ";
export var extra = "tickets-module--extra--aqE2T";
export var holder = "tickets-module--holder--N7mi3";
export var info = "tickets-module--info--M7Ket";
export var item = "tickets-module--item--F9eem";
export var items = "tickets-module--items--+L2dZ";
export var more = "tickets-module--more--2rg1f";
export var open = "tickets-module--open--wSyUr";
export var price = "tickets-module--price--L1wXi";
export var text = "tickets-module--text--qJjkp";
export var title = "tickets-module--title--AYc3e";