// extracted by mini-css-extract-plugin
export var border = "speakers-module--border--xwBZ8";
export var content = "speakers-module--content--CGa4-";
export var icon = "speakers-module--icon--V+Puo";
export var image = "speakers-module--image--4pk9p";
export var item = "speakers-module--item--tKW1+";
export var items = "speakers-module--items--T9gXP";
export var more = "speakers-module--more--UJQNz";
export var name = "speakers-module--name--36tHo";
export var overlay = "speakers-module--overlay--oiTqh";
export var placeholder = "speakers-module--placeholder--nOFi2";
export var title = "speakers-module--title--CP7kX";