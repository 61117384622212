import React from "react";
import PropTypes from "prop-types";

import { getProperty } from "@btc/shared";

import { MobilityPartners as CommonMobilityPartners } from "../common/mobility-partners";

export const MobilityPartners = ({ data }) => {
  const title = getProperty(data, "title");
  const items = getProperty(data, "items") || [];

  console.log(data);

  return <CommonMobilityPartners title={title} items={items} />;
};

MobilityPartners.defaultProps = {
  data: {},
};

MobilityPartners.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        logo: PropTypes.object.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};
