import React, { useMemo } from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";

import { Section, Text, getProperty, useFormatterDateFromTo } from "@btc/shared";

import { usePageByView } from "hooks";

import * as styles from "./schedules.module.scss";

const ItemPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  alias: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  featured: PropTypes.bool.isRequired,
}).isRequired;

export const Schedules = ({ data }) => {
  const page = usePageByView("program");
  const pagePath = getProperty(page, "path");
  const items = getProperty(data, "items") || [];

  const filteredAndSortedItems = useMemo(() => {
    if (!Array.isArray(items)) {
      return [];
    }

    items.sort((a, b) => {
      const dateA = new Date(getProperty(a, "from"));
      const dateB = new Date(getProperty(b, "from"));
      return dateA - dateB;
    });

    return items.filter((schedule) => getProperty(schedule, "featured"));
  }, [items]);

  const linkProvider = (item) => `${pagePath}/#${getProperty(item, "alias")}`;

  return Array.isArray(filteredAndSortedItems) && items.length > 0 ? (
    <Section noPaddingTop={true} noPaddingBottom={true}>
      <div className={styles.wrapper}>
        {filteredAndSortedItems.map((item, index) => (
          <Item key={index} item={item} linkProvider={linkProvider} />
        ))}
      </div>
    </Section>
  ) : null;
};

Schedules.defaultProps = {
  data: {},
};

Schedules.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(ItemPropType).isRequired,
  }).isRequired,
};

const Item = ({ item, linkProvider }) => {
  const path = linkProvider(item);
  const name = getProperty(item, "name");
  const date = useFormatterDateFromTo(getProperty(item, "from"), getProperty(item, "to"), "long");

  return (
    <Link to={linkProvider(item)} key={path} className={styles.item}>
      <div className={styles.holder}>
        <Text transform="uppercase" className={styles.date}>
          {date}
        </Text>
        <Text size="s4" weight="bold">
          {name}
        </Text>
      </div>
    </Link>
  );
};

Item.defaultProps = {
  item: null,
  linkProvider: null,
};

Item.propTypes = {
  item: ItemPropType,
  linkProvider: PropTypes.func.isRequired,
};

export const query = graphql`
  fragment HomeEventSchedulesFragment on MarkdownRemark {
    frontmatter {
      event {
        schedules {
          featured
          alias
          name
          from
          to
        }
      }
    }
  }
`;
