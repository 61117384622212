import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { Button, getProperty } from "@btc/shared";

import { usePageByView } from "hooks";

import { Speakers as CommonSpeakers } from "../common/speakers";

import * as styles from "./speakers.module.scss";

export const Speakers = ({ data }) => {
  const page = usePageByView("program");
  const pagePath = getProperty(page, "path");

  const linkProvider = (item) => {
    const talkAlias = getProperty(item, "talks.0.alias");
    const scheduleAlias = getProperty(item, "talks.0.schedule.alias");

    return pagePath && scheduleAlias && talkAlias ? `${pagePath}/#${scheduleAlias}?id=${talkAlias}` : null;
  };

  const linkText = getProperty(data, "link.text");
  const linkPath = getProperty(data, "link.path");

  return (
    <CommonSpeakers linkProvider={linkProvider} {...data}>
      <div className={styles.wrapper}>
        <Button wrapper={Link} to={linkPath} type="minimal" color="white">
          {linkText}
        </Button>
      </div>
    </CommonSpeakers>
  );
};

Speakers.defaultProps = {
  data: {},
};

Speakers.propTypes = {
  data: PropTypes.shape({
    link: PropTypes.object.isRequired,
    ...CommonSpeakers.propTypes,
  }).isRequired,
};
