import React from "react";
import PropTypes from "prop-types";

import { Section, Container, Text, Markdown, getProperty } from "@btc/shared";

import * as styles from "./ticket.module.scss";

export const Ticket = ({ data }) => {
  const items = getProperty(data, "items");
  const content = getProperty(data, "content");

  return (
    <Section size="md" color="black" noPaddingTop={true}>
      <Container>
        <Text size="s4" weight="bold" className={styles.content}>
          <Markdown content={content} />
        </Text>
        <div className={styles.items}>
          {items.map((item, index) => {
            const text = getProperty(item, "text");
            const icon = getProperty(item, "icon.publicURL");

            return (
              <div key={index} className={styles.item}>
                <img src={icon} className={styles.icon} alt={text} />
                <Text size="s1" transform="uppercase" className={styles.text}>
                  {text}
                </Text>
              </div>
            );
          })}
        </div>
      </Container>
    </Section>
  );
};

Ticket.defaultProps = {
  data: {},
};

Ticket.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
};
